<template>
  <div
    class="dropdown"
    :class="{ 'is-active': isActive }"
    v-click-outside="close"
  >
    <div class="dropdown-trigger">
      <button
        class="button is-fullwidth is-justify-content-space-between"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        @click="isActive = !isActive"
      >
        <span class="is-active has-text-weight-normal">
          {{ isCustom ? 'Modified' : 'All' }}
        </span>
        <span class="icon is-small">
          <i class="fal fa-angle-down" />
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content pb-0">
        <a
          class="dropdown-item pr-4"
          v-for="(option, index) in options"
          :key="index"
        >
          <div class="columns mb-0" @click.prevent="toggle(option)">
            <div class="column">
              <span :class="{ 'has-text-grey-light': option.disabled }">
                {{ option.label }}
              </span>
            </div>
            <div class="column is-narrow">
              <ToggleButton
                :speed="400"
                :disabled="option.disabled"
                class="is-size-7"
                v-model="option.display"
                :width="35"
                :height="20"
                :color="toggleColours"
                :sync="true"
              />
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownMultiselect',
  data: () => ({ isActive: false }),
  props: {
    options: {
      type: Array,
      required: true
    },
    isCustom: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    toggleColours() {
      return {
        checked: this.$experian ? '#426da9' : '#0000c3',
        unchecked: 'rgba(0, 0, 0, .125)'
      }
    }
  },
  methods: {
    toggle(option) {
      if (!option.disabled) {
        option.display = !option.display
        this.$emit('toggle', option)
      }
    },
    close() {
      this.isActive = false
    }
  }
}
</script>

<style lang="sass" scoped>
.dropdown,
.dropdown-trigger
  width: 100%
.dropdown-menu
  min-width: 15rem
.button:focus
  border-color: rgba(32, 30, 31, 0.29)
.dropdown-item:hover
  background-color: white !important
</style>
